.c-radio input {
  position: absolute;
  display: none;
  appearance: none;

  &:checked + label {
    &::after {
      opacity: 1;
    }
  }
}

.c-radio .label {
  position: relative;
  padding: 0 0 0 28px;
  font-size: 14px;

  @include media-breakpoint-down(xs) {
    font-size: 13px;
  }

  @include media-breakpoint-up(md) {
    padding: 0 0 0 33px;
    font-size: 16px;
    cursor: pointer;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    content: '';
    transform: translateY(-50%);
  }

  &::before {
    width: 16px;
    height: 16px;
    border: 1px solid #342b07;

    @include media-breakpoint-up(md) {
      width: 22px;
      height: 22px;
    }
  }

  &::after {
    left: 4px;
    width: 8px;
    height: 8px;
    background-color: #342b07;
    opacity: 0;

    @include media-breakpoint-up(md) {
      left: 5px;
      width: 12px;
      height: 12px;
    }
  }
}

.c-radio.c-radio--error {
  .label::before {
    border: 1px solid #ffb7b7;
    background-color: #ffdede;
  }
}
