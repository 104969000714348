.g-station-search {
  position: relative;
  padding-top: 86.13%;
  // height: 323px;
  background-image: url('/assets/images/station_bg.jpg');
  background-position: 50% 50%;
  background-size: cover;

  @include media-breakpoint-up(md) {
    padding-top: 0;
    height: 410px;
    background-image: url('/assets/images/station_bg_md.jpg');
  }

  &::before {
    position: absolute;
    top: -192px;
    left: 50%;
    z-index: 2;
    display: none;
    margin-left: 424px;
    width: 424px;
    height: 314px;
    background-image: url('/assets/images/station_obj_md.png');
    content: '';

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
  }

  &__icon {
    position: relative;
    top: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;

    @include media-breakpoint-up(md) {
      top: -18px;
      width: 94px;
      height: 94px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
      content: '';
      transition: transform 0.4s $ease-out-quad, opacity 0s 0.4s;
    }

    img {
      position: relative;
      padding: 0 0 3px;
      width: 30px;

      @include media-breakpoint-up(md) {
        width: 46px;
      }
    }
  }

  &__text {
    position: relative;
    margin: 8px 0 0;
    text-align: center;
    letter-spacing: 0.12em;
    line-height: 1.85;

    @include media-breakpoint-up(md) {
      margin: 15px 0 0;
      font-size: 15px;
    }
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 130%;
    transform: translate(-50%, -50%);

    &::before {
      display: block;
      padding-top: 100%;
      border-radius: 50%;
      background: linear-gradient(90deg, map-get($colors, 'lightblue'), map-get($colors, 'pink'));
      content: '';
      opacity: 0;
      transition: transform 0s 0.4s, opacity 0.4s $ease-out-sine;
      transform: scale(0);
    }
  }

  a:hover {
    .g-station-search__icon::before {
      @include media-breakpoint-up(md) {
        opacity: 0;
        transition: 0.8s $ease-out-quad, opacity 0.8s $ease-out-quad;
        transform: scale(1.2);
      }
    }

    .overlay::before {
      @include media-breakpoint-up(md) {
        opacity: 0.7;
        transition: transform 1s $ease-in-out-quad 0s, opacity 1s $ease-out-quad;
        transform: scale(1);
      }
    }
  }
}

.g-station-search .header {
  margin: -4px 0 0 -12px;

  @include media-breakpoint-up(md) {
    margin: 5px 0 0;
  }

  &__heading {
    position: relative;

    &::after {
      position: absolute;
      right: -30px;
      bottom: -8px;
      width: 59px;
      height: 26px;
      background-image: url('../images/station_search.png');
      background-size: contain;
      content: '';

      @include media-breakpoint-down(xs) {
        right: -20px;
      }

      @include media-breakpoint-up(md) {
        right: -54px;
        bottom: -16px;
        width: 99px;
        height: 51px;
        background-image: url('../images/station_search_md.png');
      }
    }

    picture img {
      @include media-breakpoint-up(md) {
        width: 467px;
        height: 33px;
      }
    }
  }
}
