.c-main-visual {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 140px;
  background: url('/assets/images/pattern/mv_bg.png');
  background-size: 95px;

  @include media-breakpoint-up(md) {
    padding: 0;
    height: 222px;
    background-size: auto;
  }

  &--news .header {
    .header__icon img {
      width: 25px;

      @include media-breakpoint-up(md) {
        width: 45px;
      }
    }
  }
}

.c-main-visual .header {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    max-width: 1360px;
    width: 100%;
    height: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #fff;

    @include media-breakpoint-up(md) {
      width: 94px;
      height: 94px;
    }

    img {
      @include media-breakpoint-up(md) {
        width: auto;
        height: auto;
      }
    }
  }

  &__heading {
    margin: 0 0 -3px -6px;

    img {
      @include media-breakpoint-up(md) {
        margin: 0 0 -10px -7px;
        width: auto;
        height: auto;
      }
    }
  }
}
