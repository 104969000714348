.c-button {
  display: inline-block;
  transition: background-color 0.4s $ease-out-quad, box-shadow 0.4s $ease-out-quad;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .icon-arrow-r {
    transition: transform 0.4s $ease-out-quart;
  }

  &:hover {
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    transition: background-color 0.4s $ease-out-quad, box-shadow 0.4s $ease-out-quad;

    .icon-arrow-r {
      transform: translate3d(5px, 0, 0);
    }
  }
}

.c-button--color01 {
  background-color: #2d2300;
  color: #fff;

  &:hover {
    background-color: lighten(#2d2300, 5);
  }
}

.c-button--color02 {
  border: 1px solid #c0bdb2;
  background-color: #fff;

  &:hover {
    background-color: #efefef;
  }
}
