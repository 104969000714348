.c-breadcrumb {
  padding: 12px 0;
  height: 45px;

  @include media-breakpoint-up(md) {
    padding: 20px 0;
    height: 63px;
  }
}

.c-breadcrumb .list {
  display: flex;
  align-items: center;

  &__item {
    line-height: 1;

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__item a {
    position: relative;
    display: inline-block;
    margin: 0 24px 0 0;

    @include media-breakpoint-up(md) {
      margin: 0 30px 0 0;
    }

    &:hover {
      @include media-breakpoint-up(md) {
        text-decoration: underline;
      }
    }

    img {
      width: 15px;

      @include media-breakpoint-up(md) {
        width: 18px;
        height: 16px;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: -11px;
      width: 6px;
      height: 6px;
      border-top: 1px solid #2d2300;
      border-right: 1px solid #2d2300;
      content: '';
      transform: translateY(-50%) rotate(45deg);
      pointer-events: none;

      @include media-breakpoint-up(md) {
        right: -17px;
        margin: 1px 0 0;
      }
    }
  }

  a,
  span {
    display: inline-block;
    white-space: nowrap;
    letter-spacing: 0.2em;
    font-size: 10px;
    line-height: 1;

    @include media-breakpoint-up(md) {
      padding: 3px 0;
      font-size: 13px;
    }
  }
}
