.g-form {
  padding: 0 0 60px;

  @include media-breakpoint-up(md) {
    padding: 0 0 100px;
  }

  &__flow {
    display: block;
    text-align: center;

    img {
      @include media-breakpoint-up(md) {
        width: 398px;
        height: 98px;
      }
    }
  }

  &__lead {
    margin: 25px 0 0;
    text-align: center;
    letter-spacing: 0.05em;
    font-size: 14px;
    line-height: 2.1;

    @include media-breakpoint-up(md) {
      margin: 30px 0 0;
      font-size: 15px;
      line-height: 2.67;
    }

    .required {
      display: inline-block;
      margin: 0 7px 0 0;
      padding: 4px 10px 5px;
      border-radius: 1.5px;
      background-color: #f96652;
      color: #fff;
      letter-spacing: 0.04em;
      font-weight: 500;
      line-height: 1;

      @include media-breakpoint-up(md) {
        margin: 0 10px 0 0;
        padding: 6px 18px 7px;
        border-radius: 3px;
        font-size: 14px;
      }
    }

    &--confirm {
      color: #f0472e;
    }
  }
}

.g-form form {
  .l-container {
    @include media-breakpoint-up(md) {
      padding: 0 110px;
    }
  }
}
