.c-page-others {
  position: relative;
  padding: 51px 0 98px;

  @include media-breakpoint-up(md) {
    padding: 73px 0 180px;
  }

  &::before,
  &::after {
    position: absolute;
    z-index: 1;
    background-size: contain;
    content: '';
  }

  &::before {
    top: -33px;
    right: 0;
    width: 141px;
    height: 100px;
    background-image: url('/assets/images/pages/others_obj01.png');

    @include media-breakpoint-up(md) {
      top: -117px;
      right: -26px;
      width: 424px;
      height: 314px;
      background-image: url('/assets/images/pages/others_obj01_md.png');
    }
  }

  &::after {
    bottom: 20px;
    left: 0;
    width: 106px;
    height: 85px;
    background-image: url('/assets/images/pages/others_obj02.png');

    @include media-breakpoint-up(md) {
      bottom: -35px;
      left: -26px;
      width: 241px;
      height: 221px;
      background-image: url('/assets/images/pages/others_obj02_md.png');
    }
  }

  &__inner {
    padding: 0 25px;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 0;
      width: 735px;
    }
  }

  &__button {
    margin: 28px 0 0;
    text-align: center;
  }
}

.c-page-others .others-header {
  &__heading {
    text-align: center;

    span {
      padding: 0 0 7px;
      border-bottom: 1px solid #2d2300;
      letter-spacing: 0.22em;
      font-weight: 400;
      font-size: 13px;
      font-family: $font-viga;

      @include media-breakpoint-up(md) {
        padding: 0 0 5px;
        border-bottom-width: 2px;
        font-size: 16px;
      }
    }
  }

  &__lead {
    margin: 31px 0 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 42px 0 0;
    }

    img {
      max-width: 100%;
      width: 234px;

      @include media-breakpoint-up(md) {
        width: 620px;
      }
    }
  }

  &__text {
    margin: 21px 0 0;
    text-align: center;
    letter-spacing: 0.05em;
    font-size: 14px;
    line-height: 2.15;

    @include media-breakpoint-up(md) {
      margin: 30px 0 0;
      letter-spacing: 0.12em;
      font-size: 15px;
      line-height: 2.53;
    }
  }
}

.c-page-others .c-button {
  width: 226px;
  height: 60px;
  border-radius: 30px;
  letter-spacing: 0.12em;
  font-size: 15px;

  @include media-breakpoint-up(md) {
    width: 308px;
    height: 74px;
    border-radius: 37px;
    font-size: 16px;
  }

  .icon {
    position: relative;
    top: -1px;
    right: -15px;
    display: inline-block;
    width: 14px;
    height: 12px;
    background-image: url('/assets/images/icon_arrow_white.svg');
    transform: rotate(-90deg);

    @include media-breakpoint-up(md) {
      top: 0;
      right: -10px;
      height: 14px;
    }
  }
}

.c-page-others.c-page-others--static {
  .heading {
    display: block;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      font-size: 22px;
    }
  }

  .tel {
    position: relative;
    display: inline-block;
    margin: 10px 0 0 20px;
    font-size: 25px;

    @include media-breakpoint-up(md) {
      margin: 10px 0 0 38px;
      font-size: 45px;
      pointer-events: none;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: -28px;
      margin: 1px 0 0;
      width: 21px;
      height: 21px;
      background-image: url('/assets/images/pages/inquiry/icon_tel.svg');
      background-size: contain;
      content: '';
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        left: -45px;
        margin: 3px 0 0;
        width: 35px;
        height: 35px;
      }
    }
  }

  .others-header__text {
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 15px;
    }
  }
}
