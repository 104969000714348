.g-form-table {
  margin: 25px 0 0;
  border-top: 1px dashed #c5c2b7;

  @include media-breakpoint-up(md) {
    margin: 56px 0 0;
  }

  &__row {
    padding: 35px 0 0;

    @include media-breakpoint-up(md) {
      display: flex;
      padding: 70px 16px 0 12px;
    }

    + .g-form-table__row {
      @include media-breakpoint-up(md) {
        padding: 43px 16px 0 12px;
      }
    }
  }

  .heading {
    @include media-breakpoint-up(md) {
      margin: 17px 0 0;
      width: 270px;
    }

    &__name {
      font-weight: 500;
      font-size: 15px;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }
    }

    .required {
      display: inline-block;
      margin: 0 0 0 10px;
      padding: 4px 10px 5px;
      border-radius: 1.5px;
      background-color: #f96652;
      color: #fff;
      letter-spacing: 0.04em;
      font-weight: 500;
      font-size: 13px;
      line-height: 1;

      @include media-breakpoint-up(md) {
        margin: 0;
        padding: 6px 18px 7px;
        border-radius: 3px;
        font-size: 14px;
      }
    }
  }

  .error {
    margin: 8px 0 0;
    color: #ff4b4b;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      margin: 10px 0 0;
      font-size: 15px;
    }
  }
}

.g-form-table .contents {
  margin: 12px 0 0;
  font-size: 14px;

  @include media-breakpoint-up(md) {
    margin: 0 0 0 25px;
    width: calc(100% - (270px + 25px));
    font-size: 16px;
  }

  &__inner {
    display: flex;
  }

  &--1of2 {
    display: flex;
    margin-right: -5px;
    margin-left: -5px;

    .contents__item {
      padding: 0 5px;
      width: 50%;

      @include media-breakpoint-up(md) {
        padding: 0;
        width: auto;
      }
    }

    .contents__item + .contents__item {
      @include media-breakpoint-up(md) {
        margin: 0 0 0 20px;
      }
    }
  }

  .contents__item--select + .contents__item--select {
    margin: 12px 0 0;

    @include media-breakpoint-up(md) {
      margin: 20px 0 0;
    }
  }

  .placeholder {
    margin: 5px 0 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;

    @include media-breakpoint-up(md) {
      margin: 8px 0 0;
      font-size: 14px;
    }
  }

  .age,
  .year {
    margin: 13px 0 0 6px;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      margin: 17px 0 0 15px;
      font-size: 16px;
    }
  }

  .office {
    display: inline-block;
    margin: 12px 10px 0 0;
    width: 70px;
    font-weight: 500;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      margin: 18px 13px 0 0;
      font-size: 16px;
    }
  }
}

.g-form-table .contents .radio-list {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin: 0 0 0 -36px;
  }

  &__item {
    width: 50%;

    @include media-breakpoint-up(md) {
      margin: 17px 0 0 36px;
      width: 23%;
    }

    &:nth-child(n + 3) {
      @include media-breakpoint-down(sm) {
        margin: 18px 0 0;
      }
    }
  }

  .radio-list__item--auto {
    width: auto;

    + .radio-list__item--auto {
      margin: 0 0 0 27px;

      @include media-breakpoint-up(md) {
        margin: 17px 0 0 55px;
      }
    }
  }

  &--recruit {
    .radio-list__item {
      @include media-breakpoint-up(md) {
        width: 20%;
      }
    }
  }
}

.g-form-table .contents .checkbox-list {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    align-items: center;
    margin: 17px 0 0;
  }

  &__item {
    width: 50%;

    @include media-breakpoint-up(md) {
      width: 33.33%;
    }

    &--full {
      width: 100%;

      @include media-breakpoint-down(sm) {
        margin: 20px 0 0;
      }
    }

    &:nth-child(n + 3) {
      @include media-breakpoint-down(sm) {
        margin: 20px 0 0;
      }
    }

    &:nth-child(n + 4) {
      @include media-breakpoint-up(md) {
        margin: 20px 0 0;
      }
    }

    &.c-checkbox--other {
      @include media-breakpoint-up(md) {
        width: calc(100% - 33.33%);
      }

      .checkbox-label {
        @include media-breakpoint-up(md) {
          width: 19%;
        }
      }
    }

    // &:not(.c-checkbox--other) {
    //   @include media-breakpoint-up(md) {
    //     width: auto;
    //   }
    // }
  }

  .other-textarea {
    display: block;
    margin: 12px 0 0;
    padding: 14px 20px;
    width: 100%;
    height: 50px;
    border-radius: 2.5px;
    background-color: #f5f3eb;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 25px;
      padding: 10px 20px;
      min-height: 40px;
      width: 430px;
      height: 40px;
      border-radius: 5px;
    }

    &--error {
      border: 1px solid #ffb7b7;
      background-color: #ffdede;
    }
  }
}

.g-form-table .item {
  margin: 30px 0 0;
  padding: 25px 0 0;
  border-top: 1px dashed #c5c2b7;

  @include media-breakpoint-up(md) {
    margin: 63px 0 0;
    padding: 63px 0 0;
  }
}

.g-form-table .note {
  margin: 0 0 19px;
  letter-spacing: 0.1em;
  font-size: 14px;
  line-height: 2;

  @include media-breakpoint-up(md) {
    margin: 0 0 32px;
    text-align: center;
    font-size: 15px;
  }

  > a {
    border-bottom: 1px solid #2d2300;

    &:hover {
      @include media-breakpoint-up(md) {
        border-bottom: 0;
      }
    }
  }
}

.g-form-table .button {
  text-align: center;

  + .button {
    margin: 35px 0 0;
  }

  .c-button {
    width: 226px;
    height: 60px;
    border-radius: 30px;
    letter-spacing: 0.12em;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      width: 308px;
      height: 74px;
      border-radius: 37px;
      font-size: 16px;
    }

    .icon {
      position: relative;
      top: -1px;
      right: -15px;
      display: inline-block;
      width: 14px;
      height: 12px;
      background-image: url('../images/icon_arrow_white.svg');
      transform: rotate(-90deg);

      @include media-breakpoint-up(md) {
        top: 0;
        right: -10px;
        height: 14px;
      }
    }
  }

  &--edit {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    button {
      @include media-breakpoint-up(md) {
        padding: 0 0 0 20px;
        letter-spacing: 0.12em;
      }

      &:hover {
        @include media-breakpoint-up(md) {
          text-decoration: underline;
        }
      }
    }

    .icon {
      position: relative;
      top: 1px;
      left: -20px;
      display: inline-block;
      width: 14px;
      height: 12px;
      background-image: url('../images/icon_arrow.svg');
      transform: scale(-1);

      @include media-breakpoint-up(md) {
        left: -20px;
      }
    }
  }
}

.g-form-table.g-form-table--confirm {
  .heading {
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  .contents {
    .radio-list {
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .radio-list__item {
      position: relative;
      padding-left: 1em;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      &::before {
        position: absolute;
        top: 8px;
        left: 1px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #2d2300;
        content: '';

        @include media-breakpoint-up(md) {
          top: 11px;
        }
      }
    }

    .textarea {
      margin: 7px 0 0;
      letter-spacing: 0.13em;
      font-size: 13px;
      line-height: 2;

      @include media-breakpoint-up(md) {
        margin: 15px 0 0;
        font-size: 16px;
        line-height: 2.13;
      }

      &--recruit {
        @include media-breakpoint-up(md) {
          margin: 0;
        }
      }
    }
  }

  .item {
    padding: 32px 0 0;

    @include media-breakpoint-up(md) {
      position: relative;
      padding: 70px 0 0;
    }
  }
}
