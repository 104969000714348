.g-header {
  background-color: #fff;

  &__inner {
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;

    @include media-breakpoint-up(md) {
      padding: 0 60px;
      height: 120px;
    }
  }

  &__navigation {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      margin: 0 0 0 auto;
      padding: 5px 0 0;
      height: 100%;
    }
  }

  &__menu-button {
    margin: 0 0 0 auto;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.g-header .logo {
  position: relative;
  top: -1px;

  @include media-breakpoint-up(md) {
    top: 2px;
  }

  a {
    display: block;
    font-size: 10px;
    line-height: 1;
  }

  img {
    @include media-breakpoint-up(md) {
      width: 200px;
      height: 39px;
    }
  }
}

.g-header .navigation {
  display: flex;
  height: 100%;
}

.g-header .navigation__item {
  display: flex;
  align-items: center;
  margin-left: 35px;
  height: 100%;

  &:last-child {
    margin-left: 27px;
  }

  &:hover {
    .navigation-item::before {
      opacity: 1;
    }

    .dropdown {
      visibility: visible;
      opacity: 1;
    }
  }

  &--blank {
    position: relative;
    padding-right: 15px;

    &::after {
      position: absolute;
      top: 2px;
      right: -4px;
      bottom: 0;
      display: block;
      margin: auto 0;
      width: 10px;
      height: 10px;
      background-image: url('/assets/images/blank.svg');
      background-size: 10px;
      content: '';

      @include media-breakpoint-up(md) {
        width: 12px;
        height: 12px;
        background-image: url('/assets/images/blank_md.svg');
        background-size: 12px;
      }
    }
  }
}

.g-header .navigation-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fed545;
    content: '';
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    + .dropdown {
      visibility: visible;
      opacity: 1;
    }
  }
}

.g-header .dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  visibility: hidden;
  margin: 120px 0 0;
  width: 100%;
  background: url('/assets/images/dropdown_bg.png');
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  &:hover {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    margin: 0 auto;
    padding: 60px 0 57px;
    width: 1160px;
  }

  .thumbnail {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: 4px solid #ffce61;
      content: '';
      opacity: 0;
      transition: opacity 0.25s;
    }
  }

  .link {
    &:hover {
      .thumbnail::after {
        @include media-breakpoint-up(md) {
          opacity: 1;
        }
      }
    }
  }
}

.g-header .dropdown .dropdown-list {
  display: flex;
  justify-content: center;
  margin: 0 -20px;

  &__item {
    padding: 0 20px;
  }

  .text {
    margin: 13px 0 0;
    letter-spacing: 0.08em;
    font-weight: 500;

    i {
      position: relative;
      top: -1px;
      padding: 0 0 0 9px;
      font-size: 12px;
    }
  }
}

.g-header .navigation .c-button {
  width: 180px;
  height: 70px;
  border-radius: 35px;
  font-weight: 500;
  font-size: 15px;
}

.g-header .menu-button {
  display: block;
  width: 65px;
  height: 70px;

  &__inner {
    width: 100%;
    height: 100%;
  }

  .icons {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .default {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 25px;
    height: 21px;
    transition: opacity 0.4s $ease-in-quad;

    span {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: #000;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }
  }

  .active {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 28px;
    height: 28px;
    opacity: 0;
    transition: opacity 0.4s $ease-out-quart;

    span {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: #000;

      &:nth-child(1) {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }
    }
  }

  &[aria-expanded='true'] {
    .default {
      opacity: 0;
      transition: opacity 0.4s $ease-out-quart;
    }

    .active {
      opacity: 1;
      transition: opacity 0.4s $ease-in-quad;
    }
  }
}
