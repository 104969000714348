.c-checkbox {
  &--other {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }
}

.c-checkbox input {
  position: absolute;
  display: none;
  appearance: none;

  &:checked + label {
    &::after {
      opacity: 1;
    }
  }
}

.c-checkbox .checkbox-label {
  position: relative;
  padding: 0 0 0 27px;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    content: '';
    transform: translateY(-50%);
  }

  &::before {
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #2d2300;
  }

  &::after {
    left: 3px;
    width: 10px;
    height: 8px;
    background-image: url('/assets/images/pages/recruit/entry/icon_check.svg');
    background-size: contain;
    opacity: 0;
  }
}

.c-checkbox.c-checkbox--error {
  .checkbox-label::before {
    border: 1px solid #ffb7b7;
    background-color: #ffdede;
  }
}
