.g-footer {
  &__inner {
    position: relative;
    padding: 64px 20px 77px;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 119px $gutter-md 122px;
      width: $contents-width;
    }
  }

  &__navigations {
    margin: 44px 0 0;

    @include media-breakpoint-up(md) {
      margin: 58px 0 0;
    }
  }

  &__copyright {
    border-top: 1px solid #ebeae6;
  }
}

.g-footer .copy {
  text-align: center;

  img {
    @include media-breakpoint-up(md) {
      position: relative;
      width: 641px;
      height: 35px;
    }
  }
}

.g-footer .kinds {
  display: flex;

  &__item {
    width: 50%;
  }
}

.g-footer .kinds .link {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;

  @include media-breakpoint-up(md) {
    height: 100px;
    font-size: 17px;
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 130%;
    transform: translate(-50%, -50%);

    &::before {
      display: block;
      padding-top: 100%;
      border-radius: 50%;
      background-color: #eee;
      content: '';
      opacity: 0;
      transition: transform 0s 0.4s, opacity 0.4s $ease-out-sine;
      transform: scale(0);
    }
  }

  &__label {
    position: relative;
  }

  &--general {
    background-color: map-get($colors, 'lightblue');

    .overlay::before {
      background-color: lighten(map-get($colors, 'lightblue'), 5);
    }
  }

  &--concerned {
    background-color: map-get($colors, 'pink');
    letter-spacing: 0;

    .overlay::before {
      background-color: lighten(map-get($colors, 'pink'), 5);
    }
  }

  &:hover {
    .overlay::before {
      @include media-breakpoint-up(md) {
        opacity: 1;
        transition: transform 0.7s $ease-in-out-quad 0s, opacity 1s $ease-out-quad;
        transform: scale(1);
      }
    }
  }
}

.g-footer .sm-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 18px 0 0;
  font-size: 13px;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__item {
    + .sm-navigation__item {
      position: relative;

      &::before {
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto 0;
        width: 1px;
        height: 20px;
        background-color: #ebeae6;
        content: '';
      }
    }
  }

  a {
    display: block;
    padding: 10px 25px;
    letter-spacing: 0;
  }
}

.g-footer .md-navigation {
  display: none;
  margin: 50px 0 0;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &__item {
    width: 25%;
  }
}

.g-footer .md-navigation .list {
  &__item + .list__item {
    margin-top: 11px;
  }

  &--has-children {
    .list__item + .list__item {
      margin-top: 7px;
    }

    .list__item:nth-child(2) {
      margin-top: 17px;
    }
  }
}

.g-footer .md-navigation .link {
  display: inline-block;
  overflow: hidden;
  margin: 0 -7px 0 -8px;
  padding: 5px 5px 5px 8px;
  border-radius: 4px;

  &--primary {
    font-weight: 500;
    font-size: 15px;
  }

  &--secondary {
    color: #8b887e;
    font-size: 13px;
  }

  &--blank {
    position: relative;
    overflow: visible;
    padding-right: 15px;

    &::before {
      position: absolute;
      top: 2px;
      right: -4px;
      bottom: 0;
      display: block;
      margin: auto 0;
      width: 10px;
      height: 10px;
      background-image: url('/assets/images/blank.svg');
      background-size: 10px;
      content: '';

      @include media-breakpoint-up(md) {
        width: 12px;
        height: 12px;
        background-image: url('/assets/images/blank_md.svg');
        background-size: 12px;
      }
    }
  }

  &:hover {
    &:not(span) {
      @include media-breakpoint-up(md) {
        text-decoration: underline;
      }
    }
  }
}

.g-footer .back-to-top {
  position: absolute;
  right: 20px;
  bottom: -15px;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f7f3e8;

  @include media-breakpoint-up(md) {
    right: 50px;
    bottom: -38px;
    width: 80px;
    height: 80px;
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #2d2300;
      content: '';
      opacity: 0;
      transition: opacity 0.15s $ease-out-quad;
    }
  }

  .icon-arrow-r {
    position: relative;
    display: block;
    width: 14px;
    height: 12px;
    color: #2d2300;
    font-size: 12px;
    transform: rotate(-90deg);
    transform: color 0.15s;
  }

  &:hover {
    .back-to-top__inner::before {
      @include media-breakpoint-up(md) {
        opacity: 1;
        transition: opacity 0.25s $ease-out-quad;
      }
    }

    .icon-arrow-r {
      color: #fff;
    }
  }
}

.g-footer .copyright {
  padding: 18px 0 22px;
  color: #766e54;
  text-align: center;
  letter-spacing: 0;

  @include media-breakpoint-up(md) {
    padding: 34px 0 0;
    height: 100px;
    letter-spacing: 1px;
  }

  small {
    font-size: 10px;

    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
}
