.c-input {
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 2.5px;
  background-color: #f5f3eb;
  font-size: 15px;

  @include media-breakpoint-up(md) {
    height: 62px;
    border-radius: 5px;
    font-size: 16px;
  }

  &--sm {
    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }

  &--md {
    width: 205px;

    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }

  &--lg {
    @include media-breakpoint-up(md) {
      width: 460px;
    }
  }

  &--full {
    width: 100%;
  }

  &--error {
    border: 1px solid #ffb7b7;
    background-color: #ffdede;
  }
}
