.c-pagination {
  padding: 30px 30px 0;

  @include media-breakpoint-up(md) {
    padding: 70px 0 0;
  }
}

.c-pagination .list {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    width: 705px;
  }

  &__item:first-child .list__link--arrow img {
    transform: scale(-1);
  }

  &__item {
    &--prev {
      @include media-breakpoint-up(md) {
        margin-right: 15px;
      }
    }

    &--next {
      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 1px solid #362b05;
    border-radius: 50%;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      width: 56px;
      height: 56px;
      font-size: 20px;
      transition: background-color 0.3s, color 0.3s;
    }

    &:not(.list__link--arrow) {
      font-family: $font-noto;
    }

    &--arrow {
      @include media-breakpoint-up(md) {
        width: 148px;
        border-radius: 28px;
      }

      img {
        width: 14px;
      }

      span {
        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
      }
    }

    .icon {
      @include media-breakpoint-up(md) {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 13px;
        background-image: url('/assets/images/icon_arrow.svg');
      }

      &::before {
        @include media-breakpoint-up(md) {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: 15px;
          height: 13px;
          background-image: url('/assets/images/icon_arrow_on.svg');
          content: '';
          opacity: 0;
          transition: opacity 0.3s;
          backface-visibility: hidden;
        }
      }

      &--prev {
        @include media-breakpoint-up(md) {
          left: -18px;
          transform: scale(-1);
        }
      }

      &--next {
        @include media-breakpoint-up(md) {
          right: -18px;
        }
      }
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.is-active,
    &:hover {
      background-color: #362b05;
      color: #fff;
    }

    &:hover {
      .icon::before {
        @include media-breakpoint-up(md) {
          opacity: 1;
        }
      }
    }
  }
}
