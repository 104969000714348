@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Source Han Sans';
  src: local('Source Han Sans Regular'),
    url('/assets/fonts/SourceHanSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/SourceHanSans-Regular.woff') format('woff'),
    url('/assets/fonts/SourceHanSans-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Source Han Sans';
  src: local('Source Han Sans Medium'),
    url('/assets/fonts/SourceHanSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/SourceHanSans-Medium.woff') format('woff'),
    url('/assets/fonts/SourceHanSans-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Source Han Sans';
  src: local('Source Han Sans Bold'),
    url('/assets/fonts/SourceHanSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SourceHanSans-Bold.woff') format('woff'),
    url('/assets/fonts/SourceHanSans-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?l6o5ny');
  src: url('/assets/fonts/icomoon.eot?l6o5ny#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?l6o5ny') format('truetype'),
    url('/assets/fonts/icomoon.woff?l6o5ny') format('woff'),
    url('/assets/fonts/icomoon.svg?l6o5ny#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-family: 'icomoon' !important; // stylelint-disable-line
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.icon-arrow-r::before { // stylelint-disable-line
  content: '\e900';
}
