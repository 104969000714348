html {
  min-width: map-get($grid-breakpoints, xs);

  @include media-breakpoint-up(md) {
    min-width: map-get($grid-breakpoints, xl);
  }
}

body {
  color: #2d2300;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: 'Source Han Sans', sans-serif;
  font-feature-settings: 'palt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

// Eliminate extra whitespace caused by img element that inserted by access analysis script
body > img {
  display: block;
  height: 0 !important; // stylelint-disable-line declaration-no-important
  font-size: 0;
}

img,
svg {
  vertical-align: middle;
}

ul,
ol {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}
