.g-drawer {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 3000;
  visibility: hidden;
  width: 100%;
  height: calc(100% - 70px);
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &.is-expanded {
    visibility: visible;
    opacity: 1;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__inner {
    overflow-y: auto;
    padding: 0 40px 30px;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
}

.g-drawer .primary-navigation {
  border-bottom: 1px dashed #c5c2b7;

  &__item {
    border-top: 1px dashed #c5c2b7;
  }
}

.g-drawer .primary-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 74px;
  font-weight: 500;
  font-size: 15px;

  &--link {
    &::after {
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      display: block;
      margin: auto 0;
      width: 14px;
      height: 12px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228px%22%20height%3D%2225px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%2035%2C%200)%22%20d%3D%22M28.014%2C12.203%20C28.006%2C12.276%2027.992%2C12.339%2027.974%2C12.412%20C27.921%2C12.598%2027.832%2C12.769%2027.707%2C12.927%20C27.669%2C12.977%2027.668%2C13.043%2027.623%2C13.088%20C27.595%2C13.117%2027.558%2C13.125%2027.529%2C13.150%20L17.257%2C23.579%20C16.685%2C24.159%2015.757%2C24.159%2015.184%2C23.579%20C14.611%2C22.998%2014.611%2C22.055%2015.184%2C21.474%20L23.021%2C13.518%20L1.476%2C13.518%20C0.661%2C13.518%20-0.000%2C12.847%20-0.000%2C12.019%20C-0.000%2C11.192%200.661%2C10.520%201.476%2C10.520%20L23.021%2C10.520%20L15.184%2C2.564%20C14.611%2C1.984%2014.611%2C1.041%2015.184%2C0.459%20C15.757%2C-0.122%2016.685%2C-0.122%2017.257%2C0.459%20L27.525%2C10.885%20C27.555%2C10.910%2027.594%2C10.921%2027.623%2C10.950%20C27.960%2C11.292%2028.074%2C11.758%2028.014%2C12.203%20Z%22%2F%3E%3C%2Fsvg%3E');
      background-size: 14px 12px;
      content: '';
    }
  }

  &--toggler {
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      display: block;
      margin: auto 0;
      width: 13px;
      height: 2px;
      background-color: #2d2300;
      content: '';
      backface-visibility: hidden;
    }

    &::after {
      transform: rotate(-90deg);
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(0);
      }
    }
  }

  &--blank {
    span {
      position: relative;
      padding-right: 15px;

      &::before {
        position: absolute;
        top: 2px;
        right: -4px;
        bottom: 0;
        display: block;
        margin: auto 0;
        width: 10px;
        height: 10px;
        background-image: url('/assets/images/blank.svg');
        background-size: 10px;
        content: '';
      }
    }
  }
}

.g-drawer .secondary-navigation-wrap {
  overflow: hidden;
  height: 0;
  transition: height 0.3s;
}

.g-drawer .secondary-navigation {
  padding: 0 0 27px 20px;

  &__item {
    + .secondary-navigation__item {
      margin: 24px 0 0;
    }
  }
}

.g-drawer .secondary-item {
  position: relative;
  display: block;
  padding: 0 0 0 20px;
  color: #574f33;
  font-size: 14px;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto 0;
    width: 11px;
    height: 1px;
    background-color: #898579;
    content: '';
  }
}

.g-drawer .kinds-links {
  display: flex;
  margin: 30px 0 0;

  &__item {
    width: 50%;
  }
}

.g-drawer .kinds-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 148px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
  }

  &__label {
    display: block;
    margin: 9px 0 0;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 16px;
  }

  &--general {
    background-color: map-get($colors, 'lightblue');
  }

  &--concerned {
    background-color: map-get($colors, 'pink');
  }
}

.g-drawer .contact {
  margin: 30px 0 0;
  text-align: center;

  .c-button {
    max-width: 295px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 15px;
  }
}
