.c-service-banner {
  opacity: 0;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;
  }

  &.is-active {
    opacity: 1;
    transition: opacity 1s $ease-in-sine;
  }

  li {
    margin: 25px 0 0;

    @include media-breakpoint-up(md) {
      margin: 40px 0 0;
      padding: 0 20px;
    }
  }

  a {
    position: relative;
    display: block;
    overflow: hidden;

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 130%;
      transform: translate(-50%, -50%);

      &::before {
        display: block;
        padding-top: 100%;
        border-radius: 50%;
        // background-color: rgba(255, 228, 88, 0.5);
        background-color: rgba(238, 238, 238, 0.4);
        content: '';
        opacity: 0;
        transition: transform 0s 0.4s, opacity 0.4s $ease-out-sine;
        transform: scale(0);
      }
    }

    &:hover {
      .overlay::before {
        @include media-breakpoint-up(md) {
          opacity: 1;
          transition: transform 0.7s $ease-in-out-quad 0s, opacity 1s $ease-out-quad;
          transform: scale(1);
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
