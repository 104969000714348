.c-dropdown {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 50px;
  border-radius: 2.5px;
  background-color: #f5f3eb;
  vertical-align: top;

  @include media-breakpoint-up(md) {
    height: 70px;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 52.5px;
    height: 100%;
    background-color: #2d2302;
    content: '';
    pointer-events: none;

    @include media-breakpoint-up(md) {
      width: 65px;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    margin: auto 0;
    width: 12.5px;
    height: 14px;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225%22%20height%3D%2228%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23FFF%22%20d%3D%22M11.811%2028.014a1.462%201.462%200%200%201-.725-.308c-.05-.038-.116-.038-.161-.084-.029-.027-.037-.065-.063-.093L.421%2017.257a1.449%201.449%200%200%201%200-2.073%201.506%201.506%200%200%201%202.107%200l7.966%207.836V1.475c0-.815.672-1.476%201.501-1.476.829%200%201.501.661%201.501%201.476V23.02l7.967-7.836a1.506%201.506%200%200%201%202.107%200%201.449%201.449%200%200%201%200%202.073L13.131%2027.525c-.026.03-.036.068-.065.097-.343.338-.809.451-1.255.392z%22%2F%3E%3C%2Fsvg%3E');
    background-size: 12.5px 14px;
    content: '';
    pointer-events: none;

    @include media-breakpoint-up(md) {
      right: 26px;
      width: 13px;
      height: 14px;
      background-size: 13px 14px;
    }
  }

  select {
    padding: 0 72.5px 0 20px;
    width: 100%;
    height: 100%;
    color: #7a7975;
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      padding: 0 85px 0 20px;
      font-size: 14px;
    }
  }

  &--form {
    width: 205px;

    @include media-breakpoint-up(md) {
      width: 260px;
      height: 62px;
      border-radius: 5px;
    }
  }

  &--width-lg {
    @include media-breakpoint-up(md) {
      width: 500px;
    }
  }

  &--error {
    border: 1px solid #ffb7b7;
    background-color: #ffdede;
  }
}
