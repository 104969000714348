.c-textarea {
  margin: 34px 0 0;
  padding: 20px;
  min-height: 150px;
  width: 100%;
  border-radius: 2.5px;
  background-color: #f5f3eb;
  font-size: 15px;
  appearance: none;

  @include media-breakpoint-up(md) {
    margin: 30px 0 0;
    min-height: 200px;
    border-radius: 5px;
  }

  &--invalid {
    margin: 0;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &--error {
    border: 1px solid #ffb7b7;
    background: #ffdede;
  }
}
