.l-main {
  position: relative;
  overflow: hidden;
  background-color: #fdf9e7;

  @include media-breakpoint-up(md) {
    padding: 0 60px 60px;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    display: none;
    content: '';
    transform: translate(0, -50%);

    // @include media-breakpoint-up(md) {
    //   display: block;
    // }
  }

  &::before {
    left: 25px;
    width: 10px;
    height: 239px;
    background-image: url('/assets/images/page_left_text.png');
  }

  &::after {
    right: 25px;
    width: 10px;
    height: 239px;
    background-image: url('/assets/images/page_right_text.png');
  }

  &--top {
    background-color: #fff;

    @include media-breakpoint-up(md) {
      padding: 0 60px;
    }
  }

  &--overflow {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }

    .g-station-search::before {
      @include media-breakpoint-up(md) {
        content: none;
      }
    }
  }

  &__inner {
    padding: 41px 0 0;
    background: url('../images/dots_bg.png');
    background-position: 50% -2px;
    background-size: 16px;
    box-shadow: 0 23px 77px 23px rgba(133, 105, 0, 0.05);

    @include media-breakpoint-up(md) {
      padding: 96px 0 0;
      background-position: -10px -5px;
      background-size: 45px;
    }
  }

  &__container {
    background-color: #fff;
    background-image: url('/assets/images/pattern/dot01.png.png');
    background-position: 0 0;
    background-size: 16px;
    background-repeat: repeat;

    @include media-breakpoint-up(md) {
      background-image: url('/assets/images/pattern/dot01_md.png.png');
      background-size: auto;
    }
  }
}
